<template>
    <div>
        <div class="content-nav">
            <div class="content-nav-item"
                 v-for="(item,index) in navList.children"
                 :class="{active:$route.path.indexOf(item.path)>=0}"
                 @click="tpPage(item)"
                 :key="index">
                <i class="iconfont" :class="item.icon"></i>
                <div>{{item.title}}</div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        name: "index",
        data(){
            return{
                parkId: null,
            }
        },
        computed:{
            ...mapState('layout', {
                menuList: state => state.menuList
            }),
            ...mapState('user', {
                userInfo: state => state.userInfo
            }),
            navList(){
                return this.menuList.find(item=>item.path === '/theme')
            }
        },
        created(){},
        methods:{
            tpPage({path}){
                this.$router.push({path})
            }
        }
    }
</script>

<style scoped lang="less">
</style>
